import React, { PropsWithChildren } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";


type CustomButtonProps = {
  height?: string
  width?: string
  loading?: boolean
  fontWeight?: number
  backgroundColor?: string;
} & PropsWithChildren & ButtonProps

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    back: true;
    neutral: true;
  }
}

const CustomButton = (props: CustomButtonProps) => {
  const { children, height, width, loading, fontWeight } = props;

  const renderWithLoader = () => {
    if (loading) return <CircularProgress color={"success"} size={20} />;
    return children;
  };

  return (
    <Button
      {...props}
      sx={
        {
          height: height,
          width: width,
          fontWeight: fontWeight,
        }
      }
    >
      {renderWithLoader()}
    </Button>
  );
};

export default CustomButton;