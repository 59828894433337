import React, { FC } from "react";
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowIcon from "../../../assets/right-icon.svg";
import { RouteConstants } from "../../../routes/routes-constant";

const withRouter = (WrappedComponent: FC) => (props: any) => {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();

  return (
    <WrappedComponent
      {...props}
      location={location}
      navigate={navigate}
      params={params}
    />
  );
};

const Breadcrumbs = (props: any) => {
  const { history, navigate, location: { pathname } } = props;
  const pathnames = pathname.split("/").filter((x: string) => x);

  const openHomePage = () => navigate(RouteConstants.PORTAL_PATH);

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" separator={<img src={ArrowIcon} alt="arrow" />}>
      {pathnames.length > 0 ? (
        <Link className="cursor" underline={"none"} onClick={openHomePage}><Typography
          color={"#667080"}>Home</Typography></Link>
      ) : (
        <Typography>Home</Typography>
      )}
      {pathnames.map((name: any, index: any) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        if (name === "details") return null;
        return isLast ? (
          <Typography color={"#0D9157"} key={name}>{name}</Typography>
        ) : (
          <Link key={name} onClick={() => history.push(routeTo)}>
            {name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);