import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '..';


type initialStateType = {
  paymentConfirmation: boolean
}

const initialState: initialStateType = {
  paymentConfirmation: false,
}

export const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    setPaymentConfirmationModal: (state, { payload }: { payload: boolean }) => {
      state.paymentConfirmation = payload;
    },
  },
})

const { setPaymentConfirmationModal } = modalSlice.actions;

export default modalSlice.reducer;

export const openConfirmationModal = (state: boolean) => (dispatch: AppDispatch) => {
  dispatch(setPaymentConfirmationModal(state));
}
