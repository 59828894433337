import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import api from "../../api";
import { handleError } from "../../utils/errorsUtil";


type initialStateType = {
  list: any[]
  loading: boolean
}

const initialState: initialStateType = {
  list: [],
  loading: false,
};

const portalSlice = createSlice({
  name: "portalSlice",
  initialState,
  reducers: {
    setList: (state, { payload }) => {
      state.list = payload;
    },
    setPortalLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

const { setList, setPortalLoading } = portalSlice.actions;
export default portalSlice.reducer;

export const getOrderList = () => async (dispatch: AppDispatch) => {
  dispatch(setPortalLoading(true));
  const [result, error] = await api.getOrderList();
  if (result) {
    dispatch(setList(result.items));
  }

  dispatch(setPortalLoading(false));

  if (error) {
    return dispatch(handleError(error));
  }
};