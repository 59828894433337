import React from "react";
import { Box } from "@mui/material";
import style from "./style.module.css";

const StatusChip = ({ status }: { status: string }) => {
  if (status === 'new') {
    return <Box children={status} className={`${style.statusChip} ${style.statusNew}`} />
  }
  if (status === 'paying') {
    return <Box children={status} className={`${style.statusChip} ${style.statusPaying}`} />
  }
  if (status === 'paid') {
    return <Box children={status} className={`${style.statusChip} ${style.statusPaid}`} />
  }
  if (status === 'unpaid') {
    return <Box children={status} className={`${style.statusChip} ${style.statusUnpaid}`} />
  }
  return (
    <Box children={"Error"} className={`${style.statusChip} ${style.statusNew}`} />
  )
}

export default StatusChip