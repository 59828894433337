import React, { useEffect, useState } from "react";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import { Box, Typography } from "@mui/material";
import styles from "./Details.module.css";
import DetailsTable from "./DetailsTable/DetailsTable";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { selectLoading, selectOrder, selectOrderStatus } from "../../store/Details/selectors";
import { getOrderDetails } from "../../store/Details/detailsSlice";
import Loader from "../Shared/Loader/Loader";
import DetailsFooter from "./DetailsFooter/DetailsFooter";
import { RouteConstants } from "../../routes/routes-constant";


const Details = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const orderInfo = useAppSelector(selectOrder);
  const loading = useAppSelector(selectLoading);
  const navigate = useNavigate();

  const getDetails = async () => {
    const { res } = await dispatch(getOrderDetails(params?.token || ""));
    if (!res) navigate(RouteConstants.PORTAL_PATH);
  };

  useEffect(() => {
    getDetails();
  }, []);


  const renderOrderDetails = () => {
    return orderInfo?.map(el => {
      return (
        <Box className={styles.box} key={el.name}>
          <Typography fontWeight={500} fontSize={"12px"} className={styles.name}>{el.name}</Typography>
          <Typography fontSize={"28px"} fontWeight={600}>{el.id}</Typography>
        </Box>
      );
    });
  };

  if (loading) return <Loader />;

  return (
    <>
      <Box mt={"30px"} p={"0 15px"}>
        <Breadcrumbs />
        <Box className={styles.container}>
          {renderOrderDetails()}
        </Box>
      </Box>
      <DetailsTable />
      <DetailsFooter />
    </>
  );
};


export default Details;