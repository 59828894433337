import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteConstants } from "./routes-constant";
import { mainRoutes } from "./main-routes";
import Layout from "../components/Layout";

const RoutesProvider = () => {

  const defaultRedirect = () => <Navigate to={RouteConstants.PORTAL_PATH} />;

  const renderRoutes = () => {
    return mainRoutes.map((r) => {
      return (
        <Route
          key={r.id}
          path={r.path}
          index={r.index}
          element={r.component}
        />
      );
    });
  };

  return (
    <Routes>
      <Route path={"/"} element={<Layout />}>
        <Route index element={defaultRedirect()} />
        {renderRoutes()}
      </Route>
    </Routes>
  );
};

export default RoutesProvider;