const ver = "/v1";

export const endpoints = {
  auth: {
    login: () => `/api${ver}/cashier/token/`,
  },
  order: {
    getOrderList: () => `/api${ver}/cashier/order/list/`,
    getOrderDetails: () => `/api${ver}/cashier/order/details/`,
    finishOrder: () => `/api${ver}/cashier/order/finish/`,
  },
};
