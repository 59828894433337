import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import api from "../../api";
import { ILogin } from "../../models/login.interface";
import { handleError } from "../../utils/errorsUtil";


type initialStateType = {
  isLoading: boolean
}

const initialState: initialStateType = {
  isLoading: false,
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    setLoadingLogin(state, { payload }: { payload: boolean }) {
      state.isLoading = payload;
    },
  },
});

const { setLoadingLogin } = loginSlice.actions;
export default loginSlice.reducer;

export const login = (data: ILogin) => async (dispatch: AppDispatch) => {
  dispatch(setLoadingLogin(true));
  const [result, error] = await api.loginRequest(data);

  if (result) {
    localStorage.setItem("access_token", result?.access_token);
    return true;
  }
  dispatch(setLoadingLogin(false));

  if (error) {
    return dispatch(handleError(error));
  }
};