import React from "react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import style from "./PortalTable.module.css";
import moment from "moment";
import { ViewButton } from "../../Shared/Button";
import { useAppSelector } from "../../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { details_path } from "../../../routes/routes-constant";
import StatusChip from "../../Shared/Chips";
import { selectOrderList, selectPortalLoading } from "../../../store/Portal/selectors";
import Loader from "../../Shared/Loader/Loader";
import { useTranslation } from "react-i18next";


const PortalTable = () => {
  const orderList = useAppSelector(selectOrderList);
  const navigate = useNavigate();
  const loading = useAppSelector(selectPortalLoading);
  const { t } = useTranslation();

  const handleClick = (token: string) => {
    navigate(details_path(token));
  };

  const renderTableBody = () => {
    return orderList.map((row) => (
      <TableRow
        key={row?.token}
      >
        <TableCell>
          <Box className={"cursor"} onClick={() => handleClick(row.token)}>{row.token}</Box>
        </TableCell>
        <TableCell>{row.total_quantity}</TableCell>
        <TableCell>$ {(row.total_price).toLocaleString("en-US", { minimumFractionDigits: 2 })}</TableCell>
        <TableCell><StatusChip status={row.status} /></TableCell>
        <TableCell>{moment(row.time_created).format("DD.MM.YYYY HH:mm")}</TableCell>
        <TableCell><ViewButton onClick={() => handleClick(row.token)} children={t("buttons.view")} /></TableCell>
      </TableRow>
    ));
  };

  const renderTableOrLoader = () => {
    if (loading) return (
      <Box className={style.loading_container}>
        <Loader />
      </Box>
      );
    return (
      <TableContainer component={Paper} className={style.table_container}>
        <Table stickyHeader sx={{ minWidth: 714, maxWidth: 1216 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("table.orderId")}</TableCell>
              <TableCell>{t("table.productCount")}</TableCell>
              <TableCell>{t("table.totalPrice")}</TableCell>
              <TableCell>{t("table.status")}</TableCell>
              <TableCell>{t("table.timeCreated")}</TableCell>
              <TableCell>{t("table.details")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableBody()}
          </TableBody>
        </Table>
      </TableContainer>

    );
  };


  return (
    <>
      {renderTableOrLoader()}
    </>
  );
};

export default PortalTable;