import React from "react";
import { Box, TextField } from "@mui/material";
import style from "../Portal.module.css";
import QrIcon from "../../../assets/qr-code-icon.svg";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import BoxIcon from "../../../assets/box-icon.svg";
import { useNavigate } from "react-router-dom";
import { details_path } from "../../../routes/routes-constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { selectLoading } from "../../../store/Details/selectors";
import { getOrderDetails } from "../../../store/Details/detailsSlice";
import { useFormik } from "formik";
import { IOrder } from "../../../models/order.interface";
import { validationSchema } from "./validationSchema";
import { useTranslation } from "react-i18next";

type OrderProps = {
  openQrReader: () => void
}

const Order = ({ openQrReader }: OrderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const { t } = useTranslation();

  const handleClick = async (value: IOrder, { setFieldError }: any) => {
    const orderId = value.orderId;
    const { res } = await dispatch(getOrderDetails(orderId));
    if (!res) return setFieldError("orderId", t("errors.orderTwo"));
    navigate(details_path(orderId));
  };


  const formik = useFormik({
    initialValues: {
      orderId: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleClick,
  });

  return (
    <Box className={style.mainContainer}>
      <Box className={style.container}>
        <Box className={style.block}>
          <img src={QrIcon} alt="qr-code" />
          <CustomButton
            variant={"contained"}
            height="56px"
            width={"189px"}
            fontWeight={700}
            onClick={openQrReader}
          >
            {t("buttons.scan")}
          </CustomButton>
        </Box>
        <Box className={style.block}>
          <img src={BoxIcon} alt="box" />
          <Box component={"form"} onSubmit={formik.handleSubmit} noValidate className={style.orderBlock}>
            <TextField
              fullWidth
              id="orderId"
              name="orderId"
              type="text"
              placeholder={"Order ID"}
              value={formik.values.orderId.trim()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.orderId && formik.touched.orderId)}
              helperText={formik.touched.orderId && formik.errors.orderId ? formik.touched.orderId && formik.errors.orderId : " "}
            />
            <CustomButton
              variant={"contained"}
              height="48px"
              fullWidth
              type={"submit"}
              style={{ color: "#000000" }}
              loading={loading}
              disabled={loading}
            >
              {t("buttons.submit")}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Order;