import { RouteConstants } from "./routes-constant";
import { IRoute } from "../models/route.interface";
import LoginPage from "../pages/LoginPage/LoginPage";
import PortalPage from "../pages/PortalPage/PortalPage";
import Details from "../components/Details/Details";

export const mainRoutes: IRoute[] = [
  {
    id: RouteConstants.LOGIN,
    path: RouteConstants.LOGIN_PATH,
    component: <LoginPage />,
  },
  {
    id: RouteConstants.PORTAL,
    path: RouteConstants.PORTAL_PATH,
    component: <PortalPage />,
  },
  {
    id: RouteConstants.DETAILS,
    path: RouteConstants.DETAILS_PATH,
    component: <Details />,
  },
];
