import React, { useState } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import styles from "./Header.module.css";
import Logo from "../../assets/logo/logo.png";
import CustomButton from "../Shared/CustomButton/CustomButton";
import { logout } from "../../utils/userUtil";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../routes/routes-constant";
import { useTranslation } from "react-i18next";
import EnIcon from "../../assets/en-icon.png";
import EsIcon from "../../assets/es-icon.png";
import { useResponsive } from "../../hooks/useResponsive";


const langs: any = {
  en: { nativeName: "EN", src: EnIcon },
  // es: { nativeName: "ES", src: EsIcon },
};

const Header = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { isMobile } = useResponsive();
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("en");

  const handleChange = (event: any) => {
    const lng = event.target.value;
    i18n.changeLanguage(lng);
    setCountry(lng);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const renderLogoutBtn = () => {
    if (isLoggedIn) return (
      <CustomButton
        variant="outlined"
        width={"130px"}
        onClick={logout}
        style={{ whiteSpace: "nowrap" }}
      >
        {t("buttons.logout")}
      </CustomButton>
    );
  };

  const renderLangItems = () => {
    return Object.keys(langs).map((el) => (
      <MenuItem value={el}>
        <Box fontSize={isMobile ? "12px" : "16px"} gap={"2px"} color={"#fff"} display={"flex"} alignItems={"center"}>
          <img style={{ width: "20px", height: "20px" }} src={langs[el].src} alt={el} />
          {langs[el].nativeName}
        </Box>
      </MenuItem>
    ));
  };
  return (
    <Box className={styles.header}>
      <Box>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={country}
          name="country"
          onChange={handleChange}
          variant={"outlined"}
          sx={{
            "& .MuiSelect-icon": {
              display: "none",
            },
            // "& .MuiSelect-select": {
            //   padding: 0,
            // },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: "#667080",
                "& .MuiMenuItem-root": {
                  padding: 1,
                },
              },
            },
          }}
        >
          {renderLangItems()}
        </Select>
      </Box>
      <Box
        className={styles.logo}
      >
        <Link
          style={{ display: "flex" }}
          to={RouteConstants.PORTAL_PATH}>
          <img src={Logo} alt="cashier portal" />
        </Link>
      </Box>
      {renderLogoutBtn()}
    </Box>
  );
};

export default Header;