import React from "react";
import { Box } from "@mui/material";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { RouteConstants } from "../../../routes/routes-constant";
import ArrowIcon from "../../../assets/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { finishOrder } from "../../../store/Details/detailsSlice";
import { selectCanBeFinished, selectFinishLoading, selectOrderStatus } from "../../../store/Details/selectors";
import style from "./DetailsFooter.module.css";
import { useTranslation } from "react-i18next";
import { openConfirmationModal } from "../../../store/modal/modalSlice";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

const DetailsFooter = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectFinishLoading);
  const can_be_finished = useAppSelector(selectCanBeFinished);
  const status = useAppSelector(selectOrderStatus);
  const { token } = useParams();
  const { t } = useTranslation();

  const goToHome = () => navigate(RouteConstants.PORTAL_PATH);

  const finishOrderHandler = async () => {
    if (['paid', 'unpaid'].includes(status)) {
      dispatch(openConfirmationModal(true))
    } else {
      const res = await dispatch(finishOrder(token || ""));
      if (res) navigate(RouteConstants.PORTAL_PATH);
    }
  };

  const renderFinishButton = () => {
    if (!can_be_finished) return null
    return (
      <CustomButton
        variant="contained"
        height="48px"
        width={"165px"}
        onClick={finishOrderHandler}
        loading={loading}
        disabled={loading}
        style={{ color: "#000000" }}
      >
        {t("buttons.finish")}
      </CustomButton>
    )
  }

  return (
    <Box className={style.container}>
      <CustomButton
        // @ts-ignore
        variant={"back"}
        height="48px"
        width={"128px"}
        onClick={goToHome}
      >
        <img style={{ marginRight: "10px", height: "24px" }} src={ArrowIcon} alt="arrow" />
        {t("buttons.back")}
      </CustomButton>
      {renderFinishButton()}
      <ConfirmationModal/>
    </Box>
  );
};

export default DetailsFooter;