import React from "react";
import { Box, InputLabel, TextField } from "@mui/material";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import styles from "./LoginForm.module.css";
import { useFormik } from "formik";
import { loginSchema } from "./validationSchema";
import { ILogin } from "../../../models/login.interface";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../routes/routes-constant";
import { login } from "../../../store/Login/loginSlice";
import { selectLoading } from "../../../store/Login/selectors";
import { useTranslation } from "react-i18next";

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isLoading = useAppSelector(selectLoading);

  const submitForm = async (value: ILogin) => {
    if (await dispatch(login(value))) {
      navigate(RouteConstants.PORTAL_PATH);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: submitForm,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} className={styles.formContainer}>
      <Box width={"100%"} maxWidth={"364px"} py={"40px"}>
        <Box className={styles.firstInput} marginBottom={"5px"}>
          <InputLabel className={styles.formLabel}>Username</InputLabel>
          <TextField
            fullWidth
            id="username"
            name="username"
            type="text"
            value={formik.values.username.trim()}
            onChange={formik.handleChange}
            placeholder={`${t("login.username")}`}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.username && formik.touched.username)}
            helperText={formik.touched.username && formik.errors.username ? formik.touched.username && formik.errors.username : " "}
          />
        </Box>
        <Box className={styles.secondInput} marginBottom={"26px"}>
          <InputLabel className={styles.formLabel}>Password</InputLabel>
          <TextField
            id="password"
            placeholder={`${t("login.password")}`}
            fullWidth
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.password && formik.touched.password}
            helperText={formik.touched.password && formik.errors.password ? formik.touched.password && formik.errors.password : " "}
          />
        </Box>
        <Box className={styles.button}>
          <CustomButton
            fullWidth
            variant="contained"
            height="48px"
            type={"submit"}
            loading={isLoading}
            disabled={isLoading}
          >
            {t("buttons.login")}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  )
    ;
};

export default LoginForm;