import httpClient from "./httpClient";
import { endpoints } from "./constants";
import { AxiosResponse } from "axios";
import { API_Error, API_Response, ApiHeaders, DoHttpCallResponse } from "./types";
import { ILogin } from "../models/login.interface";
import { logout } from "../utils/userUtil";

const API = {

  async loginRequest(data: ILogin) {
    return doHttpCall("POST", endpoints.auth.login(), data, {});
  },

  async getOrderList() {
    return doHttpCall("GET", endpoints.order.getOrderList(), {}, {});
  },

  async getOrderDetails(token: string) {
    return doHttpCall("GET", endpoints.order.getOrderDetails(), {}, { token });
  },

  async finishOrderReq(token: string) {
    return doHttpCall("POST", endpoints.order.finishOrder(), {}, { token });
  },

};

export default API;

function createResponse<T>(result: T, errors?: API_Error[]): DoHttpCallResponse<T> {
  return [result, errors || null];
}

async function doHttpCall<T = any>(
  method: string,
  url: string,
  payload: any,
  params: any,
): Promise<DoHttpCallResponse<T>> {
  try {
    const access_token = localStorage.getItem("access_token");

    let result: AxiosResponse<API_Response> | null = null;
    const headers: ApiHeaders = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
    };

    if (access_token) {
      headers["Authorization"] = `Bearer ${access_token}`;
    }

    if (method === "GET") {
      result = await httpClient.get(url, { headers, params });
    } else if (method === "POST") {
      result = await httpClient.post(url, { ...payload }, { headers, params });
    } else if (method === "PUT") {
      result = await httpClient.put(
        url,
        Array.isArray(payload) ? payload : { ...payload },
        { headers, params },
      );
    } else if (method === "DELETE") {
      result = await httpClient.delete(url, { headers, params: payload });
    }

    if (result?.status === 200) {
      return createResponse<any>(result?.data);
    }
    return createResponse(null, [{ code: "UNHANDLED_REJECTION", message: "Unhandled rejection" }]);
  } catch (error: Error | any) {
    if (error.response) {
      if (error.response.status === 401) {
        if (error.response.config.url !== endpoints.auth.login()) {
          console.log("Unauthorized");
          logout();
        }
      }
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    return createResponse(null, error || "Unknown error");

  }
}
