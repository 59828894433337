import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import style from "./DetailsTable.module.css";
import { useAppSelector } from "../../../hooks/redux";
import { selectProducts } from "../../../store/Details/selectors";
import { useTranslation } from "react-i18next";


const DetailsTable = () => {
  const rows = useAppSelector(selectProducts);
  const { t } = useTranslation();

  const renderTableRow = () => {
    return rows.map((row) => (
      <TableRow
        key={row.product_item.id}
      >
        <TableCell className={style.image_box}>
          <img src={process.env.REACT_APP_SERVER_URL + row.product_item.product.image.url} className={style.image} alt={""} />
          {row.product_item.product.name}
        </TableCell>
        <TableCell>{row.quantity}</TableCell>
        <TableCell>{row.product_item.configuration[0].value}</TableCell>
        <TableCell>{row.sku ?? '-'}</TableCell>
        <TableCell>$ {(row.price).toLocaleString("en-US", { minimumFractionDigits: 2 })}</TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper} className={style.table_container}>
      <Table stickyHeader sx={{ minWidth: 714, maxWidth: 1216 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("table.productName")}</TableCell>
            <TableCell>{t("table.productCount")}</TableCell>
            <TableCell>{t("table.size")}</TableCell>
            <TableCell>{t("table.sku")}</TableCell>
            <TableCell>{t("table.totalAmount")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRow()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailsTable;