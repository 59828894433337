import { handleError } from "../utils/errorsUtil";
import { AppDispatch } from "../store";
import { t } from 'i18next'

export const parseQrData = (data?: string) => (dispatch: AppDispatch) => {
  if (data) {
    const [type, res] = data.split('=')
    if (type !== 'ms.qrcode') {
      dispatch(handleError({ errorMessage: t("errors.scanData") }))
      return false
    }
    if (res) {
      return res
    }
    return false
  }
  return false
}

export const normalizeCurrencyType = (currency: string) => {
  return currency.toUpperCase();
}

export const normalizeCoinType = (coin: string) => {
  return coin.replace(/_/g, "").toUpperCase();
}