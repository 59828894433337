export class RouteConstants {
  static readonly LOGIN = "login";
  static readonly PORTAL = "portal";
  static readonly DETAILS = "details";

  static readonly LOGIN_PATH = `/${RouteConstants.LOGIN}`;
  static readonly PORTAL_PATH = `/${RouteConstants.PORTAL}`;
  static readonly DETAILS_PATH = `/${RouteConstants.DETAILS}/:token`;
}

export const details_path = (token = ':token') => `/${RouteConstants.DETAILS}/${token}`