import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../index";
import api from "../../api";
import { handleError } from "../../utils/errorsUtil";
import moment from "moment";


type initialStateType = {
  order: any[]
  products: any[]
  loading: boolean
  finishLoading: boolean
  can_be_finished: boolean
  status: string
  total_price: number
}

const initialState: initialStateType = {
  order: [],
  products: [],
  loading: false,
  finishLoading: false,
  can_be_finished: false,
  status: '',
  total_price: 0,
};

const detailsSlice = createSlice({
  name: "detailsSlice",
  initialState,
  reducers: {
    setOrder: (state, { payload }) => {
      state.order = payload;
    },
    setProducts: (state, { payload }) => {
      state.products = payload;
    },
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload;
    },
    setFinishLoading: (state, { payload }: { payload: boolean }) => {
      state.finishLoading = payload;
    },
    setCanBeFinished: (state, { payload }: { payload: boolean }) => {
      state.can_be_finished = payload
    },
    setOrderStatus: (state, { payload }: { payload: string}) => {
      state.status = payload;
    },
    setTotalPrice: (state, { payload }: { payload: number}) => {
      state.total_price = payload;
    }
  },
});

const { setOrder, setProducts, setLoading, setFinishLoading, setCanBeFinished, setOrderStatus, setTotalPrice } = detailsSlice.actions;
export default detailsSlice.reducer;

export const getOrderDetails = (token: string) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  const [result, error] = await api.getOrderDetails(token);

  if (result) {
    const orderInfo = [
      { name: "Order ID", id: result.token },
      { name: "Kiosk ID", id: result.kiosk.short_id },
      { name: "Product count", id: result.total_quantity },
      {
        name: "Total price",
        id: `$ ${(result.total_price || 0).toLocaleString("en-US", { minimumFractionDigits: 2 })}`,
      },
      { name: "Status", id: result.status[0].toUpperCase() + result.status.slice(1) },
      { name: "Time created", id: moment(result.time_created).format("DD.MM.YYYY HH:mm") },
    ] || [];

    dispatch(setOrder(orderInfo));
    dispatch(setProducts(result.products));
    dispatch(setCanBeFinished(result.can_be_finished))
    dispatch(setLoading(false));
    dispatch(setOrderStatus(result.status));
    dispatch(setTotalPrice(result.total_price))
    return {
      res: true,
    };
  }

  if (error && !result) {
    dispatch(handleError(error));
    dispatch(setLoading(false));
    return {
      res: false,
      error: error,
    };
  }

};

export const finishOrder = (token: string) => async (dispatch: AppDispatch) => {
  dispatch(setFinishLoading(true));
  const [result, error] = await api.finishOrderReq(token);

  if (result) {
    return true;
  }

  dispatch(setFinishLoading(false));

  if (error && !result) {
    dispatch(handleError(error));
    return false;
  }
};

export const performFinishLoading = (loading: boolean) =>  (dispatch: AppDispatch) => {
  dispatch(setFinishLoading(loading));
}