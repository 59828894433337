import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Notification from "../Notification/Notification";

const Layout = () => {
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Header />
      <Box component={"main"}
           flexGrow={1}
           display={"flex"}
           flexDirection={"column"}
           maxWidth={"1230px"}
           width={"100%"}
           m={"0 auto"}>
        <Outlet />
      </Box>
      <Notification />
    </Box>
  );
};

export default Layout;