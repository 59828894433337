import React, { useState } from "react";
import ScanQr from "./ScanQr/ScanQr";
import PortalTable from "./PortalTable/PortalTable";
import Order from "./Order/Order";

const Portal = () => {
  const [isQrOpen, setIsQrOpen] = useState(false);

  const renderQrReader = () => {
    if (isQrOpen) return <ScanQr setIsQrOpen={setIsQrOpen} isQrOpen={isQrOpen} />;
  };

  const openQrReader = () => setIsQrOpen(true);

  return (
    <>
      <Order openQrReader={openQrReader} />
      <PortalTable />
      {renderQrReader()}
    </>
  );
};

export default Portal;