import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import style from "./ConfirmationModal.module.css";
import { useTranslation } from "react-i18next";
import { normalizeCoinType, normalizeCurrencyType } from "../../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import { selectPaymentConfirmation } from "../../../store/modal/selectors";
import { openConfirmationModal } from "../../../store/modal/modalSlice";
import { finishOrder, performFinishLoading } from "../../../store/Details/detailsSlice";
import { RouteConstants } from "../../../routes/routes-constant";
import { selectOrderStatus, selectTotalPrice } from "../../../store/Details/selectors";


const ConfirmationModal = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectPaymentConfirmation)
  const status = useAppSelector(selectOrderStatus);
  const total_price = useAppSelector(selectTotalPrice)
  const { t } = useTranslation();
  const [currentStep, SetCurrentStep] = useState(0);


  const { token } = params;


  const closeModal = useCallback(() =>{
    dispatch(openConfirmationModal(false))
  }, [])

  const finishSelectedPayment = useCallback(async () => {
    const res = await dispatch(finishOrder(token || ""));
    dispatch(performFinishLoading(false));
    closeModal();
    if (res) navigate(RouteConstants.PORTAL_PATH);
  }, [])


  const steps = useMemo(() => {
    if (status === 'unpaid') {
      return [
        {
          text: `${t('modals.unpaidText1')} ${total_price} USD ${t('modals.unpaidText2')}`,
          onFinish: () => SetCurrentStep(1),
        },
        {
          text: `${t('modals.paidText')}`,
          onFinish: () => finishSelectedPayment(),
        },
      ]
    }
    if (status === 'paid') {
      return [
        {
          text: `${t('modals.paidText')}`,
          onFinish: () => finishSelectedPayment(),
        },
      ]
    }
  }, [total_price, status, finishSelectedPayment])


  const onNextStep = useCallback(() => {
    if (!steps) return;
    // @ts-ignore
    steps[currentStep].onFinish();
  }, [steps, currentStep])


  const renderConfirmationDetails = () => {

    if (!steps) return;
    let recieverText = '';
    let inferency = '';

    recieverText = steps[currentStep].text;

     return (
       <Box>
        <DialogContentText color={'#FFFFFF'} lineHeight={'40px'} fontSize={32} fontWeight={500}>
         {recieverText}
        </DialogContentText>
        <DialogContentText marginTop={'16px'} color={'#FFFFFF6A'} fontSize={20} fontWeight={400}>
          {inferency}
        </DialogContentText>
      </Box>
    );
  };

  const renderButtons = () => {
    return (
      <Box className={style.buttons_box}>
        <CustomButton 
        height="48px"
        width="101px"
        onClick={closeModal}
        classes={{root: style.button}}
        variant={"neutral"}
        >
          {t("buttons.no")}
        </CustomButton>

        <CustomButton 
        onClick={onNextStep}
        height="48px" 
        width="138px" 
        variant="contained"
        >
          {t("buttons.confirm")}
        </CustomButton>
      </Box>
    );
  };

  return (
    <Dialog PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }} open={open} onClose={closeModal}>
      <DialogContent className={style.container}>
        <Box className={style.box}>
          {renderConfirmationDetails()}
          {renderButtons()}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ConfirmationModal;
