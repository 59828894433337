import React, { useEffect } from "react";
import Portal from "../../components/Portal/Portal";
import { useAppDispatch } from "../../hooks/redux";
import { getOrderList } from "../../store/Portal/portalSlice";
import { MINUTES_MS } from "../../constants/constants";

const PortalPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getOrderList());
    // const interval = setInterval(() => {
    //   dispatch(getOrderList());
    // }, MINUTES_MS);
    // return () => clearInterval(interval);
  }, []);

  return (
    <Portal />
  );
};

export default PortalPage;